import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { ApiHttpService } from './core/services/api-http.service';
import { ApiEndpointsService } from './core/services/api-endpoints.service';
import { ConfigService } from './initializer/config.service';
import { AuthService } from './core/services/auth/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermsOfUseComponent } from './shared/components/dialog/terms-of-use/terms-of-use.component';
import { environment } from 'src/environments/environment';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { ModalDialogData } from './models/modelDialog.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title(title: any) {
    throw new Error('Method not implemented.');
  }

  baseData: any;
  sessionUser: any;
  isAuthenticated: boolean = false;
  selectedLP: any;
  selectedVehicle: any;
  error: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService,
    private config: ConfigService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.authService.startTokenRefreshTimer();
    this.authService.logoutAfterThreeHours();
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.config.sessionuser.error) {
      this.error = this.config.sessionuser.error;
    }

    router.events
      .pipe(
        filter((routerEvent: Event) => routerEvent instanceof NavigationEnd)
      )
      .subscribe(() =>
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0)
      );
    this.getFavicon();
  }

  // getSessionUser() {
  //   this.apiHttpService
  //     .get(this.apiEndpointsService.getSessionUser())
  //     .subscribe(
  //       (response) => {
  //         let sessionUser = JSON.parse(JSON.stringify(response));
  //         let userId = sessionUser.data.id;
  //         let name = `${sessionUser.data.firstName} ${sessionUser.data.lastName}`;

  //         let userLanguage = ['en', 'de'].includes(
  //           sessionUser.data.state.lang.toLowerCase()
  //         )
  //           ? sessionUser.data.state.lang.toLowerCase()
  //           : 'en';
  //         this.userService.setUserConfig(
  //           `${sessionUser.data.firstName} ${sessionUser.data.lastName}`,
  //           userLanguage,
  //           sessionUser.data.id
  //         );
  //         localStorage.setItem(
  //           'user',
  //           JSON.stringify({
  //             name: name,
  //             languageCode: userLanguage,
  //             id: userId
  //           })
  //         );
  //         this.translate.use(userLanguage);
  //         this.error = null;
  //       },
  //       (error) => {
  //         if (error.status == 403) {
  //           this.error = 'ACCESS_DENIED';
  //         }
  //       }
  //     );
  // }

  handleLPS(lps: any) {
    this.selectedLP = lps;
    this.baseData = { ...this.baseData, lps };
    this.router.navigate(
      [
        `lp/${this.selectedVehicle.id}/vehicle/${this.selectedVehicle.id}/dashboard`
      ],
      { state: { ...this.baseData } }
    );
    // this.router.navigate([`lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/dashboard`], { state: { lps: this.selectedInvestor, vehicle: this.currentVehicle, baseData: this.baseData } })
  }

  handleVehicle(vehicle: any) {
    this.selectedVehicle = vehicle;
    this.baseData = { ...this.baseData, vehicle };
    this.router.navigate(
      [
        `lp/${this.selectedVehicle.id}/vehicle/${this.selectedVehicle.id}/dashboard`
      ],
      { state: { ...this.baseData } }
    );
    // this.router.navigate(['/'], { state: { ...this.baseData } });
  }

  ngOnInit() {
    if (this.config.sessionuser.error) {
      this.error = this.config.sessionuser.error;
    } else {
      if (
        !this.config.sessionuser.error &&
        !this.config.sessionuser.data.events.confirmedTermsOfUse
      ) {
        this.dialog.open(TermsOfUseComponent, {
          disableClose: true,
          data: {}
        });
      }
      if(this.config.sessionuser.data.events.firstLogin && !this.config.sessionuser.data.events.firstLoginAfter2024Redesign) {
        this.openModalRedesignDialog(true);
      }
    }

    console.log(this.config);

    if (
      this.config.sessionuser.data &&
      this.config.baseData &&
      this.config.baseData.data
    ) {
      this.sessionUser = this.config.sessionuser.data;
      this.baseData = this.config.baseData.data;
      let userLanguage = ['en', 'de'].includes(
        this.sessionUser.state.lang.toLowerCase()
      )
        ? this.sessionUser.state.lang.toLowerCase()
        : 'en';
      this.userService.setUserConfig(
        `${this.sessionUser.firstName} 
        ${this.sessionUser.lastName}`,
        userLanguage,
        this.sessionUser.id
      );
      this.setAppLanguage();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  }

  openModalRedesignDialog(event: boolean): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      data: <ModalDialogData>{
        titleKey: '',
        contentKey: '',
        positiveMessageKey: 'OK',
        negativeMessageKey: '',
        isValid: '2'
      }
    });
  }

  setAppLanguage() {
    this.translate.addLangs(['en', 'de']);
    const { languageCode } = JSON.parse(this.userService.getUserConfig());
    this.translate.use(languageCode);
  }

  createFaviconLink(rel: string, href: string, sizes: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    link.setAttribute('sizes', sizes);
    document.head.appendChild(link);
    return link;
  }

  setFavicon(faviconUrl: string): void {
    // Remove existing favicon links if any
    document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"]').forEach((link) => link.remove());

    const timestamp = `?t=${Date.now()}`;
    const standardUrl = faviconUrl + timestamp;
    const mediumUrl = faviconUrl.replace('.ico', '-medium.ico') + timestamp;
    const largeUrl = faviconUrl.replace('.ico', '-large.ico') + timestamp;

    // Create and add favicons and store the references to the link elements
    const faviconLink = this.createFaviconLink('icon', standardUrl, '16x16');
    const appleTouchIcon = this.createFaviconLink('apple-touch-icon',mediumUrl,'180x180');
    const androidIcon = this.createFaviconLink('icon', largeUrl, '192x192');

    [mediumUrl, largeUrl].forEach((url, index) => {
      const img = new Image();
      img.onerror = () => {
        if (index === 0) appleTouchIcon.href = standardUrl;
        else androidIcon.href = standardUrl;
      };
      img.src = url;
    });
  }

  getFavicon() {
    const currentEnv = new URL(environment?.frontendBaseUrl).hostname.split('.')[0];
    const hostname = window.location.hostname;
    const domain = hostname.split('.');
    const urlPrefixPath = '/lp-portal/favicons/';
    const defaultFavicon =
      environment?.assetBaseUrl + urlPrefixPath + 'default.ico';
    let faviconUrl: any;

    if (domain[0] === currentEnv && domain[0] != 'lp') {
      faviconUrl =
        environment?.assetBaseUrl +
        urlPrefixPath +
        domain[0].toLowerCase() +
        '.ico';
    } else if (domain.length >= 2) {
      faviconUrl =
        environment?.assetBaseUrl +
        urlPrefixPath +
        domain[1].toLowerCase() +
        '.ico';
    }
    const img = new Image();
    img.onload = () => this.setFavicon(faviconUrl);
    img.onerror = () => this.setFavicon(defaultFavicon);
    img.src = faviconUrl;
  }
}
